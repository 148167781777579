import { cloneDeep, isEmpty } from 'lodash';
import { highAvailabilityServerAddress, featureRestrictedUsers, allowNotificationForAll, notiticationsAllowedClientIds } from '../config';
import {
  getDifferenceFromCurrentDate,
  formatDate,
  DD_MMM_YYYY,
} from 'utils/dbaiLocalizationHelper';
import { trackingConfig } from 'constants/enumMappings';
import Bowser from "bowser";
import { incidentSupportLevel } from 'constants/enumMappings';
import moment from 'moment';

/**
 * Convert given "number" into "short-number-format"
 *
 * @param {Number} number Numeric value to be formatted into short-number-format
 * @returns {String} will return short-number in string format.
 */
export const shortNumberFormat = (number) => {
  if (number >= 1000000000000) {
    return (number / 1000000000000).toFixed(1).replace(/\.0$/, '') + ' T';
  }
  if (number >= 1000000000) {
    return (number / 1000000000).toFixed(1).replace(/\.0$/, '') + ' B';
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1).replace(/\.0$/, '') + ' M';
  }
  if (number >= 1000) {
    return (number / 1000).toFixed(1).replace(/\.0$/, '') + ' K';
  }
  return number;
};

/**
 * Rounds off the given value upto 'precision' places of decimal
 *
 * @param {Number} value Numeric value to be rounded off
 * @param {Number} precision Places after dot to round off upto
 */
export const roundOff = (number, precision = 0) => {
  let precisionMultiplier = Math.pow(10, precision);
  // Number.EPSILON offset helps round off figures ending in 5 like 0.05, 1.145, uniformly across all browsers
  return Math.round((number + Number.EPSILON) * precisionMultiplier) / precisionMultiplier;
};

/**
 *
 * @param {Object} appliedSearchParam contain all params in {key:value} format
 * @returns {String} will return search string
 */
export const constructSearchString = (appliedSearchParam) => {
  let searchString = '';
  if (!isEmpty(appliedSearchParam)) {
    const appliedParams = Object.keys(appliedSearchParam);
    if (appliedParams.length > 0) {
      appliedParams.forEach((paramsKey) => {
        if (paramsKey && !isEmpty(appliedSearchParam[paramsKey])) {
          searchString = `${searchString}${paramsKey}=${appliedSearchParam[paramsKey]}&`;
        }
      });
    }
  }

  return searchString.slice(0, -1);
};

/**
 * Checks if the user-agent is online by using Navigator API and pinging a highly available server on the internet
 */
export const checkInternetConnectivity = () => {
  if (!navigator.onLine) {
    // This indicates that the user-agent is definitely offline
    const userOnlinePromise = new Promise((resolve, reject) => {
      resolve(false);
    });
    return userOnlinePromise;
  } else {
    // Try pinging a highly available server to double check if the user-agent is able to access the internet
    return pingServer(highAvailabilityServerAddress).then((isAvailable) => isAvailable);
  }
};

/**
 * Ping a server existing at the passed server address
 * @param {String} serverAddress A server url or dns
 * @returns {Promise} Resolution of promise returns a boolean indicating if the hit on the server was successful
 */
export const pingServer = async (serverAddress) => {
  if (!serverAddress) {
    return false;
  }

  try {
    await fetch(serverAddress, { mode: 'no-cors' }); // Fetch trial, if successful, will continue here instead of jumping to catch
    return true;
  } catch (error) {
    return false;
  }
};

export const eventBus = {
  on(event, callback) {
    document.addEventListener(event, (e) => callback(e.detail));
  },
  dispatch(event, data) {
    document.dispatchEvent(new CustomEvent(event, { detail: data }));
  },
  remove(event, callback) {
    document.removeEventListener(event, callback);
  },
};

/* Credits: To Title Case © 2018 David Gouch | https://github.com/gouch/to-title-case */
// Converts a string to Title Case
// eslint-disable-next-line no-extend-native
export const toTitleCase = (string) => {
  const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|v.?|vs.?|via)$/i;
  const alphanumericPattern = /([A-Za-z0-9\u00C0-\u00FF])/;
  const wordSeparators = /([ :–—-])/;

  return string
    .split(wordSeparators)
    .map(function (current, index, array) {
      if (
        /* Check for small words */
        current.search(smallWords) > -1 &&
        /* Skip first and last word */
        index !== 0 &&
        index !== array.length - 1 &&
        /* Ignore title end and subtitle start */
        array[index - 3] !== ':' &&
        array[index + 1] !== ':' &&
        /* Ignore small words that start a hyphenated phrase */
        (array[index + 1] !== '-' || (array[index - 1] === '-' && array[index + 1] === '-'))
      ) {
        return current.toLowerCase();
      }

      /* Ignore intentional capitalization */
      if (current.substr(1).search(/[A-Z]|\../) > -1) {
        return current;
      }

      /* Ignore URLs */
      if (array[index + 1] === ':' && array[index + 2] !== '') {
        return current;
      }

      /* Capitalize the first letter */
      return current.replace(alphanumericPattern, function (match) {
        return match.toUpperCase();
      });
    })
    .join('');
};

/**
 * @return clientID of logged in user
 */
export const getLogedinUserClientId = () => {
  if (typeof window !== 'undefined' && window.localStorage) {
    const user = JSON.parse(window.localStorage.getItem('user'));
    const clientID = user?.user?.clientId;
    return clientID;
  } else return '';
}


/**
 *
 * @returns theme preference of user for application
 */
export const getInitialTheme = () => {
  //return light theme as default
  return 'light';
};

export const isAccessibleByUser = (userId) => !featureRestrictedUsers.includes(userId);

export const getTimeDifferenceWithUnit = (date) => {
  const diffInYrs = getDifferenceFromCurrentDate(date, 'years');
  const diffInMnths = getDifferenceFromCurrentDate(date, 'months');
  const diffInDys = getDifferenceFromCurrentDate(date, 'days');
  const diffInHrs = getDifferenceFromCurrentDate(date, 'hours');
  const diffInMin = getDifferenceFromCurrentDate(date, 'minutes');
  const diffInSec = getDifferenceFromCurrentDate(date, 'seconds');

  if (diffInYrs > 0) {
    return `${diffInYrs} yrs`;
  } else if (diffInMnths > 0) {
    return `${diffInMnths} mth`;
  } else if (diffInDys > 0) {
    return `${diffInDys} day`;
  } else if (diffInHrs > 0) {
    return `${diffInHrs} hrs`;
  } else if (diffInMin > 0) {
    return `${diffInMin} min`;
  } else if (diffInSec > 0) {
    return `few secs`;
  } else if (isEmpty(date)) {
    return '';
  } else {
    return `few secs`;
  }
};

/**
 * Check if notification feature should be enabled
 *
 * @param {String} clientId clientId of the currently logged in user
 * @returns {Boolean} 
 */
export const isNotificationAllowed = (clientId) => {

  // Notification can either be allowed for all clients in one go or only for particular clients based on clientIds

  if ((typeof allowNotificationForAll === 'string' && JSON.parse(allowNotificationForAll.toLowerCase())) === true ||
    notiticationsAllowedClientIds.includes(clientId)) {
    return true;
  }

  return false;
}

export const getVehicleStatusType = (lastActive) => {
  const duration = getDifferenceFromCurrentDate(lastActive, 'minutes');
  if (duration < 5) {
    return 'active';
  } else if (duration >= 5 && duration < 20) {
    return 'inactive';
  } else {
    return 'offline';
  }
};

export const formatVehicleRegistrationNumber = (number) => number;

export const getTimeStampOfLastTracked = (timeStamp, label = '') => {
  const differenceInHours = getDifferenceFromCurrentDate(timeStamp, 'hours');
  if (differenceInHours > 24) {
    return moment(timeStamp).format('DD MMM YYYY HH:mm');
    // return `${formatDate(timeStamp, DD_MMM_YYYY)}, ${formatDate(timeStamp, 'HH:mm')}`;
  } else {
    const duration = getTimeDifferenceWithUnit(timeStamp);
    return duration ? `${label} ${duration} ago` : '';
  }
};

export const getStatusText = (type) =>
  trackingConfig.vehicleSortingOptions.find((sortObj) => sortObj?.key === type)?.value ?? '';

export const handleLastRequestPath = (type) => {
  switch (type) {
    case 'save': {
      if (!sessionStorage.getItem('requestPath')) {
        sessionStorage.setItem('requestPath', window.location.pathname);
      }
      return;
    }
    case 'get': {
      return sessionStorage.getItem('requestPath');
    }
    case 'remove': {
      sessionStorage.removeItem('requestPath');
      return;
    }
    default: break;
  }
}

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

export function checkMobile() {
  const pageWidth = window.innerWidth;
  const BREAKPOINT = 991;

  return pageWidth <= BREAKPOINT;

}

export function canPlayH265() {
  const browser = Bowser.getParser(window.navigator.userAgent);
  const isValidBrowser = browser.satisfies({
    // Todo: Add all supported browsers
    chrome: ">=107",
  });

  return isValidBrowser;
}

export function goFullscreen(elementSelector) {
  var element = document.querySelector(elementSelector);
  if (element.requestFullscreen) {
    element.requestFullscreen();
  } else if (element.webkitRequestFullScreen) {
    element.webkitRequestFullScreen();
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen();
  }
}

export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen();
  }
}



export function convertToJson(arr = []) {
  if (!arr?.length) return {};
  else {
      let jsonData = {};
      arr.forEach((item, index) => {
          jsonData[item?.keyName] = { ...item };
          if (item?.type === 'object') {
              jsonData[item?.keyName].value = convertToJson(arr[index]?.value);
          }
      })
      return cloneDeep(jsonData);
  }
}

export function convertJsontoArray(jsn = {}) {
  if (!Object?.values(jsn)?.length) return [];
  else {
      let arr = [];
      Object?.values(jsn)?.forEach(item => {
          const prop = { ...item };
          if (prop?.type === 'object') {
              prop.value = convertJsontoArray(prop?.value);
          }
          arr.push(prop);
      })
      return cloneDeep(arr);
  };
}

export function hasDuplicateKey (config = []){
  if(config?.length === 0 ) return false;
  
  let keyCollection = new Set();
  
  for(let prop of config) {
    
    if(keyCollection?.has(prop?.keyName?.trim()))
      return true;
    else keyCollection.add(prop?.keyName?.trim());

    if(prop?.type === 'object')
      if(hasDuplicateKey(prop?.value))
        return true;
  }
  
  return false;
};

export function roundOffNumber(floatNumber) {
  return Math.round(floatNumber*100)/100;
}

export function formatDuration(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const hourStr = (hours % 24).toString();
  const minuteStr = (minutes % 60).toString();
  const dayStr = days.toString();

  let result = '';
  if (days === 1) {
    result += dayStr + ' day ';
  } else if (days > 1) {
    result += dayStr + ' days ';
  }
  if (hours === 1) {
    result += hourStr + ' hour ';
  } else if (hours > 1 || result.length > 0) {
    result += hourStr + ' hours ';
  }
  if (minutes === 1) {
    result += minuteStr + ' minute';
  } else {
    result += minuteStr + ' minutes';
  }

  return result.trim();
}

export function formatDurationShort(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const hourStr = (hours % 24).toString();
  const minuteStr = (minutes % 60).toString();
  const dayStr = days.toString();

  let result = '';
  if (days >= 1) {
    result += dayStr + 'd ';
  }
  if (hours >= 1 || result.length > 0) {
    result += hourStr + 'h ';
  }
  if (minutes >= 1) {
    result += minuteStr + 'm';
  }

  return result
}

export function formatDurationObj(milliseconds) {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const hourStr = (hours % 24).toString();
  const minuteStr = (minutes % 60).toString();
  const dayStr = days.toString();

  return {dayStr, hourStr, minuteStr}
}

export const getClock = (dayStr, hourStr, minuteStr) => {
  return <div className='d-flex justify-content-center align-items-start clock'>
      <div className='text-center'>
          <div className='d-flex justify-content-around clock-section'>
              <div className='clock-block'> {dayStr}</div>
              <div>:</div>
          </div>
          <div className="clock-text">DD</div></div>
      <div className='text-center'>
          <div className='d-flex justify-content-around clock-section'>
              <div className='clock-block'>{hourStr}</div>
              <div>:</div></div>
          <div className='clock-text'>HH</div></div>
      <div className='text-center'>
        <div className='d-flex justify-content-around clock-section'>
          <div className='clock-block'>{minuteStr} </div>
          <div></div>
        </div>
        <div className='clock-text'>MM</div></div>
  </div>
}
export const getTurnAroundTimeValue = (tat, createdAt, supportLevel) => {
  let currentTime = new Date().getTime()
  if (tat) {
      let difference = Math.floor(tat);
      let { dayStr, hourStr, minuteStr } = formatDurationObj(tat)
      return <div className='align-items-center tat'>
               <div className='tat-subtext pl-4'>Closed in</div>
          <div className='d-flex align-items-start pb-1 pr-1'>
            <i className={`fa fa-clock px-1 tat-icon-${difference > incidentSupportLevel[supportLevel] ? 'red' : 'green'}`} aria-hidden="true"></i>
            {getClock(dayStr, hourStr, minuteStr)}
       </div>
      </div>
  } else {
      let difference = Math.floor((currentTime - createdAt));
      if (difference > incidentSupportLevel[supportLevel]) {
          // Time crossed SLA
          let elapsedTime = currentTime - (createdAt + (incidentSupportLevel[supportLevel]))
          let { dayStr, hourStr, minuteStr } = formatDurationObj(elapsedTime)

          return <div className='align-items-center tat'>
                          <div className='tat-subtext pl-4'>Exceeded</div>
              <div className='d-flex align-items-start pb-1'>
                <i className="fa fa-clock px-1 tat-icon-red pr-1" aria-hidden="true"></i> 
                {getClock(dayStr, hourStr, minuteStr)}
              </div>
          </div>
      } else {
          // Time is in range of SLA
          let { dayStr, hourStr, minuteStr } = formatDurationObj((incidentSupportLevel[supportLevel] - difference))

          return <div className='align-items-center tat'>
                          <div className='tat-subtext pl-3'>Time Remaining</div>
              <div className='d-flex align-items-start pb-1'>
                <i className="fa fa-clock tat-icon-green pr-1" aria-hidden="true"></i> 
              {getClock(dayStr, hourStr, minuteStr)}
              </div>
          </div>
      }
  }
}

export function getRandomColor() {
  var letters = '0123456789ABCDEF';
  var color = '#';
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

export const getOnScreenEventSeverity = (severity) => {
  if (severity <= 1) {
    return "Low"
  }
  else if (severity > 1 && severity < 3) {
    return "Medium"
  }
  else if (severity >= 3) {
    return "High"
  }
  return "Low"
}


export const triggerDownload = (fileUrl, fileName) => {
  const link = document.createElement('a');
  link.href = fileUrl;
  link.download = fileName || 'video.mp4';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const triggerFileDownload = async(url, fileName)=> {
  try {
    return new Promise(async (resolve, reject) => {
      const response = await fetch(url);
      if (!response.ok) {
        reject('File not found or network error');
      }
      const blob = await response.blob();
      const blobUrl = window.URL.createObjectURL(blob);
      const anchor = document.createElement('a');
      anchor.href = blobUrl;
      anchor.download = fileName || 'video.mp4';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      window.URL.revokeObjectURL(blobUrl);
      resolve(fileName)
      })
  } catch (error) {
      console.error("Download error:", error.message);
  }
}

export const formatDateTimeForInput = (date) => {
  const padZero = (num) => (num < 10 ? `0${num}` : num);

  const year = date.getFullYear();
  const month = padZero(date.getMonth() + 1); 
  const day = padZero(date.getDate());
  const hours = padZero(date.getHours());
  const minutes = padZero(date.getMinutes());

  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

export const capatalise = (str) => str.charAt(0).toUpperCase() + str.slice(1);
